import React, { useMemo } from "react";
import {
  Heading,
  Box,
  Flex,
  Image as ChakraImage,
  Icon,
  chakra,
} from "@chakra-ui/react";
import useSWR from "swr";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { ArrowDownCircle } from "react-feather";

import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import SearchBar from "@/components/common/SearchBar";
import NewProductsSection from "@/components/Home/NewProductsSection";
import Category from "@/components/Home/Category";
import GaragesSection from "./GaragesSection";
import RecommendedProducts from "./RecommendedProducts";
import ForYouProductsSection from "./ForYouProducts";
import { useTranslation as useTranslationi18n } from "next-i18next";
import useDeviceSize from "@/utils/useDeviceSize";
import CustomHead from "@/components/common/CustomHead";

const { publicRuntimeConfig } = getConfig();

const Home = (): React.ReactElement => {
  const [width] = useDeviceSize();
  const { i18n } = useTranslationi18n();
  const { t } = useTranslation();
  const {
    data: categoriesData,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useSWR("/category");

  const recommendedCategoriesIds = useMemo(() => {
    if (categoriesData?.categories?.length > 0) {
      return {
        realEstateCatgoryId: categoriesData?.categories?.find(
          (category: { name: string }) => category?.name === "realEstate"
        )?.id,
        automotiveCategoryId: categoriesData?.categories?.find(
          (category: { name: string }) => category?.name === "automotive"
        )?.id,
        fashionCategoryId: categoriesData?.categories?.find(
          (category: { name: string }) => category?.name === "fashion"
        )?.id,
        electronicsCategoryId: categoriesData?.categories?.find(
          (category: { name: string }) => category?.name === "electronics"
        )?.id,
      };
    } else
      return {
        realEstateCatgoryId: null,
        automotiveCategoryId: null,
        fashionCategoryId: null,
        electronicsCategoryId: null,
      };
  }, [categoriesData]);

  const categories = useMemo(() => {
    if (categoriesData?.categories?.length) {
      const servicesIndex = categoriesData?.categories?.findIndex(
        (category: { name: string }) => category?.name === "services"
      );

      let allCategories = [...categoriesData?.categories];

      const freeCategory = {
        id: 0,
        name: "free",
        productsCount: categoriesData?.freeProductsCount,
      };

      if (servicesIndex !== -1)
        allCategories.splice(servicesIndex, 0, freeCategory);
      else allCategories.push(freeCategory);

      const allCategoriesSorted = [...allCategories].sort(
        (a: { productsCount: number }, b: { productsCount: number }) =>
          b.productsCount - a.productsCount
      );

      return allCategoriesSorted;
    } else {
      return [];
    }
  }, [categoriesData]);

  return (
    <>
      <CustomHead home />
      <Flex bg="white">
        <Flex
          className="main-container"
          px={{ base: "15px", sm: "50px", xl: 0 }}
          mx="auto"
        >
          <ChakraImage
            src={
              width < 600
                ? `${publicRuntimeConfig.basePath}/banner-compare-real-estate${
                    i18n?.language == "pl"
                      ? ""
                      : i18n?.language == "uk"
                      ? "-ua"
                      : "-en"
                  }.jpg`
                : `${publicRuntimeConfig.basePath}/banner-compare${
                    i18n?.language == "pl"
                      ? ""
                      : i18n?.language == "uk"
                      ? "-ua"
                      : "-en"
                  }.jpg`
            }
            alt=""
            mx="auto"
            maxW="100%"
          />
        </Flex>
      </Flex>

      <SearchBar home />

      <Box
        pb="50px"
        id="section-categories"
        borderTopColor="red.500"
        borderTopWidth="2px"
        position="relative"
      >
        <Box
          className="main-container"
          px={{ base: "15px", sm: "50px", xl: 0 }}
          mx="auto"
        >
          <Heading size="lg" variant="box" bg="red.500" color="white">
            {t("home.popularCategories")}
            <chakra.a href="#section-categories" lineHeight={0}>
              <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
            </chakra.a>
          </Heading>

          <Flex justify="center" wrap="wrap">
            {categoriesLoading && <Loader />}

            {categoriesError && <ErrorMessage />}

            {categories?.map(
              (category: {
                id: number;
                name: string;
                productsCount: number;
              }) => (
                <Category key={category?.id} category={category} />
              )
            )}
          </Flex>
        </Box>
      </Box>

      <GaragesSection />

      <ForYouProductsSection />

      <NewProductsSection />

      <RecommendedProducts
        realEstateCategoryId={recommendedCategoriesIds.realEstateCatgoryId}
        automotiveCategoryId={recommendedCategoriesIds.automotiveCategoryId}
        fashionCategoryId={recommendedCategoriesIds.fashionCategoryId}
        electronicsCategoryId={recommendedCategoriesIds.electronicsCategoryId}
      />
    </>
  );
};

export default Home;

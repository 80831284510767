import React from "react";
import {
  Heading,
  Flex,
  Image as ChakraImage,
  LinkBox,
  LinkOverlay,
  Box,
} from "@chakra-ui/react";
import getConfig from "next/config";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();

type CategoryProps = {
  category: any;
};

const Category = ({ category }: CategoryProps): React.ReactElement => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <LinkBox
      p="7.5px 0"
      role="group"
      cursor="pointer"
      onClick={() => {
        if (category?.name === "realEstate") {
          const flatCategory =
            category?.children?.find((c) => c?.name === "flat") ||
            category?.children?.[0];

          router.push(
            `/p?category=${category?.id}&categoryName=realEstate&mainSubCategory=${flatCategory?.id}`
          );
        } else if (category?.name === "automotive") {
          const carsCategory =
            category?.children?.find((c) => c?.name === "automotive_cars") ||
            category?.children?.[0];

          router.push(
            `/p?category=${category?.id}&categoryName=automotive&mainSubCategory=${carsCategory?.id}`
          );
        } else
          category?.name === "free"
            ? router.push(`/p?free=true`)
            : router.push(`/p?category=${category?.id}`);
      }}
    >
      <Flex
        align="center"
        justify="center"
        w="100%"
        px="65px"
        pb="27px"
        mx="auto"
        transition="all .25s ease-in-out"
        _groupHover={{
          transform: "scale(1.05)",
        }}
        position="relative"
        className="category-img"
      >
        <Box position="absolute" top={0} left={0}>
          <ChakraImage
            src={`${publicRuntimeConfig.basePath}/garage-new.png`}
            alt=""
          />
        </Box>
        <ChakraImage
          src={`${publicRuntimeConfig.basePath}/${category?.name}.png`}
          alt=""
          w="100%"
          h="100%"
          objectFit="contain"
          position="relative"
        />
      </Flex>
      <Heading
        mb={0}
        mt="0px"
        variant="blue"
        textAlign="center"
        fontSize="14px"
        px="32px"
        py="2px"
      >
        <LinkOverlay>{`${t(`categories.${category?.name}`)}`}</LinkOverlay>
      </Heading>
    </LinkBox>
  );
};

export default Category;
